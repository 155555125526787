import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Spacer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  ${({
    isLarge,
    isMedium,
    isSmall,
    isVertical,
    size
  }) => {
    if (isSmall) size = 10;
    if (isMedium) size = 20;
    if (isLarge) size = 30;
    return isVertical ? css`height: ${size}px;` : css`width: ${size}px;`;
  }}
`;

Spacer.defaultProps = {
  size: 5
};

Spacer.propTypes = {
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isVertical: PropTypes.bool,
  size: PropTypes.number
};

export default Spacer;
