/* eslint-disable max-len */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import moment from 'moment';
import { MainContext } from '../../contexts/mainContext';
import { auth } from 'services/firebase';

import { Spacer, Error, Loading, Text, NoConnection, Modal } from 'components';
import { Container, InputContainer, BoxContainer, FlexContainer, SampleTagsContainer } from './styles';
import PoHeader from 'compositions/PoHeader';
import PoQuantity from 'compositions/PoQuantity';
import SectionHeader from 'compositions/SectionHeader';

import Tag from './components/Tag';
import TagBisnago from './components/TagBisnago';

import { removeInfo, setBuffer } from '../../utils/storage';
import { NotificationError, NotificationSuccess, NotificationWarning } from '../../utils/toast';
import { getStorageBoxes, barcodeSeparator, validateTag, isInvalidNumber } from '../../utils/helpers';
//import { GetOrderInfo } from '../../graphql/subscriptions/subscriptions.graphql';
import { GetCurrentOrder, GetTotalBoxes, CurrentShift, GetSampleBoxes } from '../../graphql/queries/queries.graphql';
import { INSERT_SAMPLE_BOX, INSERT_SAMPLE_INCREMENT, SET_BOX, SET_MULTIPLE_BOXES, UPDATE_SAMPLE_BOX, DELETE_SAMPLE_BOX } from '../../graphql/mutations';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import ScannerDetector from 'js-scanner-detection';
import History from './components/History';
import { EditIcon } from '../../components/assets/icons';


function AppContainer(props) {
  const { idEquip } = props;
  let id = Number(idEquip);
  const formRef = useRef(null);
  const formTagRef = useRef(null);
  const [tagData, setTagData] = useState({ barcode: '12315;0;4546' });
  const [tagModal, setTagModal] = useState(false);
  const [sampleModal, setSampleModal] = useState(false);
  const [boxes, setBox] = useState(() => {
    let tags = localStorage.getItem('tags');
    try {
      let tagsParsed = JSON.parse(tags);
      return tagsParsed;
    } catch (error) {
      console.error('Error parsing tags');
    }
    return [];
  });
  const [sampleBoxes, setSampleBox] = useState(() => {
    let tags = localStorage.getItem('sample-tags');
    try {
      let tagsParsed = JSON.parse(tags);
      return tagsParsed;
    } catch (error) {
      console.error('Error parsing sample tags');
    }
    return [];
  });
  const [updateModal, setUpdateModal] = useState(false);
  const [totalSampleInput, setTotalSampleInput] = useState(0);
  const [sampleInput, setSampleInput] = useState(0);
  const [editsampleInput, setEditSampleInput] = useState(0);
  const { state, dispatch } = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const { currentOrder: order, isOffline, userName, id_enterprise, id_site, equipment } = state;


  /* const [
    GetInitialOrderInfo,
    {
      data: currentOrderData,
      loading: currentOrderLoading,
    }
  ] = useLazyQuery(GetOrderInfo2, {
    variables: { id_equipment: id },
    onCompleted: (data) => {
      if (data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.v_current_production_orders });
        getTotalBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.v_current_production_orders[0].id_production_order
          }
        });
        getSampleBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.v_current_production_orders[0].id_production_order
          }
        });
      }
    }
  }); */

  const [getTotalBoxes, { data: totalBoxData }] = useLazyQuery(GetTotalBoxes, {
    onCompleted: (data) => {
      localStorage.setItem('tags', JSON.stringify(data?.v_scanned_boxes));
      setBox(data?.v_scanned_boxes);
    }
  });

  const [getSampleBoxes, { data: sampleBoxData }] = useLazyQuery(GetSampleBoxes, {
    onCompleted: (data) => {
      localStorage.setItem('sample-tags', JSON.stringify(data?.sample_boxes));
      setSampleBox(data?.sample_boxes);
    }
  });

  const [getShift, { data: shiftData }] = useLazyQuery(CurrentShift, {
    variables: { id_equipment: id },
    onCompleted: () => {
      if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
    },
    onError: (error) => {
      if (error.networkError) dispatch({ type: 'NETWORK_STATUS', payload: true });
    }
  });

  const { data: currentOrderData, startPolling, stopPolling } = useQuery(GetCurrentOrder, {
    variables: { id_equipment: id },
    onCompleted: (data) => {
      if (data?.v_get_current_job_infos_client4_119?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.v_get_current_job_infos_client4_119 });
        getTotalBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.v_get_current_job_infos_client4_119[0].id_production_order
          }
        });
        getSampleBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.v_get_current_job_infos_client4_119[0].id_production_order
          }
        });
      }
    },
    fetchPolicy: 'no-cache'
  });

  /* useSubscription(GetOrderInfo, {
    variables: { id_equipment: id },
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.subscriptionData.data.v_current_production_orders });
        if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
        getSampleBoxes({
          variables: {
            id_equipment: id,
            id_production_order: data.subscriptionData.data.v_current_production_orders[0].id_production_order
          }
        });
      }
    }
  }); */

  const [setScannedBox] = useMutation(SET_BOX, {
    onCompleted: () => {
      getTotalBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });
    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        if (error?.graphQLErrors[0]?.extensions?.code === 'data-exception') {
          NotificationError('Dados incorretos! Revise a etiqueta.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }

      }
    }
  });

  const [setMultipleBoxes,
    {
      loading: multiBoxesLoading
    }] = useMutation(SET_MULTIPLE_BOXES, {
      onCompleted: () => {
        setBuffer([]);
        getTotalBoxes({
          variables: {
            id_equipment: id,
            id_production_order: order[0].id_production_order
          }
        });
        NotificationSuccess('Dados enviados!');
      }, onError: (error) => {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixas duplicadas.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }
        setBuffer([]);
      }
    });

  const [insertSampleBox] = useMutation(INSERT_SAMPLE_BOX, {
    onCompleted: () => {
      NotificationSuccess('Amostra inserida com sucesso!');
      getSampleBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });

    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        if (error?.graphQLErrors[0]?.extensions?.code === 'data-exception') {
          NotificationError('Dados incorretos! Revise a etiqueta.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }

      }
    }
  });

  const [insertSampleIncrement] = useMutation(INSERT_SAMPLE_INCREMENT, {
    onCompleted: () => {
      NotificationSuccess('Amostra inserida com sucesso!');
      getSampleBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });

    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        if (error?.graphQLErrors[0]?.extensions?.code === 'data-exception') {
          NotificationError('Dados incorretos! Revise a etiqueta.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }

      }
    }
  });

  const [updateSampleBox] = useMutation(UPDATE_SAMPLE_BOX, {
    onCompleted: () => {
      NotificationSuccess('Amostra atualizada com sucesso!');
      getSampleBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });

    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        NotificationError('Ocorreu um erro no servidor!');
      }
    }
  });

  const [deleteSampleBox] = useMutation(DELETE_SAMPLE_BOX, {
    onCompleted: () => {
      NotificationSuccess('Amostra removida com sucesso!');
      getSampleBoxes({
        variables: {
          id_equipment: id,
          id_production_order: order[0].id_production_order
        }
      });
    },
    onError: (error) => {
      if (error.networkError) {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
        NotificationError('Erro de conexão! As próximas caixas serão armazenadas para envio posterior.');
      } else {
        if (error?.graphQLErrors[0]?.extensions?.code === 'constraint-violation') {
          NotificationError('Não foi possível o envio! Caixa duplicada.');
        }
        if (error?.graphQLErrors[0]?.extensions?.code === 'data-exception') {
          NotificationError('Dados incorretos! Revise a etiqueta.');
        } else {
          NotificationError('Ocorreu um erro no servidor!');
        }

      }
    }
  });

  useEffect(() => {
    if (!id_enterprise) {
      auth().signOut();
      removeInfo();
    } else {
      //GetInitialOrderInfo();
      getShift();
      startPolling(55000);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);

    const options = {
      onComplete: (barcode) => {
        console.log(barcode);
        formRef.current.setFieldValue('barcode', barcode.toLowerCase());
        formRef.current.submitForm();
        //handleScan(barcode);
      }
    };

    const scannerDetector = new ScannerDetector(options);

    return () => {
      stopPolling();
    };

  }, []);

  useEffect(() => {
    if (!isOffline) {
      let storageBoxes = getStorageBoxes();
      let allBoxData = [];
      if (storageBoxes) {
        if (storageBoxes?.length) {
          for (let i in storageBoxes) {
            const separator = barcodeSeparator(storageBoxes[i].barcode);
            let boxArrayData = storageBoxes[i].barcode.split(separator);
            allBoxData.push(
              {
                box_order_number: boxArrayData[1],
                increment: boxArrayData[2],
                id_enterprise,
                id_equipment: id,
                id_order: boxArrayData[0],
                id_production_order: storageBoxes[i].id_production_order,
                id_site: id_site,
                ts_value: storageBoxes[i].timestamp
              }
            );
          }
          setMultipleBoxes({ variables: { objects: allBoxData } });

        }
      }
    }

  }, [isOffline]);

  useEffect(() => {
    if (tagData.number) {
      window.print();
    }
  }, [tagData]);

  function formatJob(job) {
    let idOrder = String(job);

    if (idOrder?.length <= 5) {
      return `0${job}`;
    } else {
      return job;
    }
  }

  const sendData = (data) => {
    data.barcode = data.barcode.toLowerCase();
    const separator = barcodeSeparator(data.barcode);
    const barcodeSplited = data.barcode.split(separator);
    /*    let currentNumber = Number(barcodeSplited[1]);
       let boxNumbers = boxes?.map((item) => item.box_order_number);
       let boxMaxValue = Math.max(...boxNumbers);
       let diff = currentNumber - boxMaxValue;
   
       if (isInvalidNumber(diff)) {
         diff = 0;
       }
       if (diff > 1) {
         NotificationError(
           `${diff} etiquetas não foram encontradas. Revise o número da última etiqueta para prosseguir`
         );
         return;
       }
    */
    if (validateTag(data.barcode, boxes, isOffline, order)) {
      if (Number(barcodeSplited[1]) !== 0) {
        setScannedBox({
          variables: {
            box: barcodeSplited[1],
            qtd: order[0].box_quantity || Number(barcodeSplited[2]),
            id_enterprise,
            id_equip: id,
            id_order: order[0].id_order,
            id_prod_order: order[0].id_production_order,
            site: id_site,
            ts: moment().format()
          }
        }
        );
      }

      let number = barcodeSplited[1];
      const printData = {
        number: `${Number(number) + 1}`,
        client: order[0]?.nm_client,
        size: `${order[0]?.diameter} x ${order[0]?.length}`,
        due_date: order[0]?.due_date,
        product: order[0]?.txt_product,
        codProd: order[0]?.label_product_id,
        codEsp: order[0]?.specification_id,
        orderNum: order[0]?.order_number,
        job: formatJob(order[0]?.id_order),
        boxQty: order[0].box_quantity,
        barcode: `${order[0]?.id_order};${Number(number) + 1};${order[0].box_quantity}`,
        hour: moment().format('HH:mm:ss'),
        date: moment().format('DD/MM/YYYY'),
        line: equipment?.name,
        id_equip: id
      };
      setTagData(printData);
    }
  };

  function saveOnBuffer(data) {
    data.barcode = data.barcode.toLowerCase();
    data.id_production_order = order[0]?.id_production_order;
    const separator = barcodeSeparator(data.barcode);
    const barcodeSplited = data.barcode.split(separator);
    if (barcodeSplited[1] === '0') return;
    let storageBoxes = getStorageBoxes();

    if (validateTag(data.barcode, boxes, isOffline, order)) {
      if (storageBoxes && storageBoxes.length) {
        storageBoxes.push(data);
        setBuffer(storageBoxes);
      } else {
        setBuffer([data]);
      }
      let number = barcodeSplited[1];
      const printData = {
        number: `${Number(number) + 1}`,
        client: order[0]?.nm_client,
        size: `${order[0]?.diameter} x ${order[0]?.length}`,
        due_date: order[0]?.due_date,
        product: order[0]?.txt_product,
        codProd: order[0]?.label_product_id,
        codEsp: order[0]?.specification_id,
        orderNum: order[0]?.order_number,
        job: formatJob(order[0]?.id_order),
        boxQty: order[0].box_quantity,
        barcode: `${order[0]?.id_order};${Number(number) + 1};${order[0].box_quantity}`,
        hour: moment().format('HH:mm:ss'),
        date: moment().format('DD/MM/YYYY'),
        line: equipment?.name,
        id_equip: id
      };
      setTagData(printData);
    }
  }

  function handleSubmit() {
    const data = formRef.current.getData();

    data.timestamp = moment().format();
    try {
      data.shift = shiftData.agg_eqvalues_shift[0].cd_shift;
    } catch (error) {
      data.shift = '';
    }

    if (!isOffline) {
      sendData(data);
    } else {
      if (validateTag(data.barcode, boxes, isOffline, order)) {
        saveOnBuffer(data);
      }
    }
  }

  function handlePrintTag() {
    const data = formTagRef.current.getData();
    let validNumber = Number(data.number);

    if (validNumber < 0) {
      NotificationError(
        `${validNumber} Não é uma etiqueta válida`
      );
      return;
    }

    setTagModal(false);
    const barcodeData = `${order[0]?.id_order};${validNumber};${order[0].box_quantity}`;
    const printData = {
      number: `${validNumber}`,
      client: order[0]?.nm_client,
      size: `${order[0]?.diameter} x ${order[0]?.length}`,
      due_date: order[0]?.due_date,
      product: order[0]?.txt_product,
      codProd: order[0]?.label_product_id,
      codEsp: order[0]?.specification_id,
      orderNum: order[0]?.order_number,
      job: formatJob(order[0]?.id_order),
      boxQty: order[0].box_quantity,
      barcode: barcodeData,
      hour: moment().format('HH:mm:ss'),
      date: moment().format('DD/MM/YYYY'),
      line: equipment?.name,
      id_equip: id
    };
    setTagData(printData);
    formTagRef.current.reset();
  }

  const insertSampleTag = () => {
    insertSampleIncrement({
      variables: {
        box: 0,
        qtd: Number(totalSampleInput),
        id_enterprise,
        id_equip: id,
        id_order: order[0].id_order,
        id_prod_order: order[0].id_production_order,
        site: id_site,
        ts: moment().format(),
        should_increment: true
      }
    });
  };

  const handleDeleteSampleBox = (params) => {
    deleteSampleBox({
      variables: {
        id_equip: params?.id_equip,
        id_prod_order: params?.id_prod_order,
        id_box: params?.id_box
      }
    });
  };

  if (currentOrderData) {
    if (!currentOrderData.v_get_current_job_infos_client4_119.length) {
      return <Error error="Nenhuma ordem de produção encontrada x_x" />;
    }
  }

  if (multiBoxesLoading || loading) return <Loading />;
  let totalBoxesQuantity = totalBoxData?.scanned_boxes_aggregate?.aggregate?.sum?.increment;
  let sampleIncrement = sampleBoxes?.[0]?.increment;
  let isDisableSampleTag = sampleBoxes?.length === 0;
  let boxRead = totalBoxData?.v_scanned_boxes?.length ?? 0;
  let boxReadTotal = totalBoxData?.v_scanned_boxes?.length ?? 0;
  if (!totalBoxesQuantity) totalBoxesQuantity = 0;
  if (!sampleIncrement) sampleIncrement = 0;

  if (sampleBoxes?.length > 1) {
    boxReadTotal += sampleBoxes?.length - 1;
  }

  let sampleBoxesDefault = sampleBoxes?.filter((item) => item?.should_increment) ?? [];
  let sampleBoxesRemain = sampleBoxes?.filter((item) => item?.should_increment === false) ?? [];
  let remainingSamples = sampleBoxesDefault?.[0]?.increment ?? 0;

  sampleBoxesRemain?.map((item) => {
    if (remainingSamples > 0) {
      remainingSamples = remainingSamples - item?.increment;
    }
  });

  return (
    <Container>
      <NoConnection />
      <PoHeader operator={userName} order={order} />
      <Spacer isVertical size={5} />

      <BoxContainer>
        <Text>Número da ultima caixa: <b> {totalBoxData?.v_scanned_boxes?.[0]?.box_order_number || ''} </b></Text>
        <Text>Total caixas: <b>{boxReadTotal}</b></Text>
      </BoxContainer>

      <SectionHeader icon="ChartBars" title="Quantidade" />
      <PoQuantity boxQuantity={totalBoxesQuantity} order={order} sampleIncrement={sampleIncrement} />

      <Spacer isVertical size={20} />

      {/* Manual input   */}
      <div style={{
        width: '100%',
        paddingLeft: '40px',
        paddingRight: '40px'
      }}>
        <InputContainer>
          <div className="barcode-form-container">
            <div className="box-quantity">
              <span style={{ color: '#2F80ED', fontWeight: 'bold', fontSize: '25px' }}>{totalBoxesQuantity}</span>
              <Text isBold noWrap> Quantidade em Caixas </Text>
            </div>
            <Spacer isVertical size={20} />
            <button className="tag-btn" onClick={() => {
              setTagModal(true);
            }}>
              Gerar Etiqueta
            </button>
            <Form className="barcode-form" ref={formRef} onSubmit={handleSubmit}>
              <div style={{ display: 'flex', marginTop: '20px' }}>
                <Input className="barcode-input" name="barcode"
                  placeholder="Faça leitura do código de barras ou digite" type="text"
                />
                <button className="barcode-btn" type="submit">Confirmar</button>
              </div>
            </Form>
          </div>

          <SampleTagsContainer>
            {(sampleBoxes?.length > 0) ?
              <div style={{ display: 'flex', justifyContent: 'center', width: '700px', }}>
                <div className="box-quantity">
                  <span style={{ color: '#2F80ED', fontWeight: 'bold', fontSize: '25px' }}>{sampleBoxes[0]?.increment}</span>
                  <button id="edit-sample-btn" onClick={() => setSampleModal(true)}>
                    Total de Amostras  <EditIcon />
                  </button>
                </div>
              </div>
              :
              <div style={{ display: 'flex', marginTop: '20px', }}>
                <input className="total-sample-input" type="number" min={0} max={99999}
                  value={totalSampleInput}
                  onChange={(e) => setTotalSampleInput(e.target.value)}
                />
                <button className="sample-btn" type="button" onClick={insertSampleTag}>
                  Inserir Total de Amostras
                </button>
              </div>
            }

            <div style={{ display: 'flex', marginTop: '70px', }}>
              <input className="total-sample-input" type="number" min={0} max={remainingSamples}
                value={sampleInput}
                onChange={(e) => setSampleInput(e.target.value)}
              />
              <button className={`sample-btn ${(isDisableSampleTag) ? 'disabled' : ''}`} type="button"
                onClick={() => {
                  if (Number(sampleInput) > remainingSamples) {
                    NotificationWarning(`Quantidade máxima dever ser ${remainingSamples}`);
                    return;
                  }
                  if (Number(sampleInput) > 0 && !isDisableSampleTag) {
                    let boxNumber = sampleBoxes?.[sampleBoxes?.length - 1]?.box_order_number ?? 0;

                    insertSampleBox({
                      variables: {
                        box: Number(boxNumber) + 1,
                        qtd: Number(sampleInput),
                        id_enterprise,
                        id_equip: id,
                        id_order: order[0].id_order,
                        id_prod_order: order[0].id_production_order,
                        site: id_site,
                        ts: moment().format(),
                        should_increment: false
                      }
                    });
                    const printData = {
                      number: `${Number(boxNumber) + 1}`,
                      client: order[0]?.nm_client,
                      size: `${order[0]?.diameter} x ${order[0]?.length}`,
                      due_date: order[0]?.due_date,
                      product: order[0]?.txt_product,
                      codProd: order[0]?.label_product_id,
                      codEsp: order[0]?.specification_id,
                      orderNum: order[0]?.order_number,
                      job: formatJob(order[0]?.id_order),
                      boxQty: Number(sampleInput),
                      barcode: `${order[0]?.id_order};${Number(boxNumber) + 1};${Number(sampleInput)}`,
                      hour: moment().format('HH:mm:ss'),
                      date: moment().format('DD/MM/YYYY'),
                      line: equipment?.name,
                      isSample: true,
                      id_equip: id
                    };
                    setTagData(printData);
                  }
                }}>
                Gerar etiqueta de Amostra
              </button>
            </div>
          </SampleTagsContainer>
        </InputContainer>

        {/* Generate tag   */}
        <Modal isOpen={tagModal} onClickToClose={() => setTagModal(false)}>
          <FlexContainer size={60} direction="column">
            <p style={{ fontWeight: 'bold', fontSize: 22 }}>Gerar Etiqueta</p>
          </FlexContainer>

          <Form className="tag-form" ref={formTagRef}>
            <Input className="tag-input" name="number" placeholder="Número da caixa" type="number"
              min="0" max="999999" step="1"
            />
          </Form>
          <FlexContainer size={60} direction="row">
            <button className="cancel-btn" onClick={() => setTagModal(false)}>Cancelar</button>
            <button className="barcode-btn" onClick={handlePrintTag}>Imprimir</button>
          </FlexContainer>

        </Modal>

        {/* Edit sample quantity   */}
        <Modal isOpen={sampleModal} onClickToClose={() => setSampleModal(false)}>
          <FlexContainer size={60} direction="row">
            <EditIcon />
            <p style={{ fontWeight: 'bold', fontSize: 22, marginLeft: '5px' }}>Editar Total de Amostras</p>
          </FlexContainer>

          <Form className="tag-form">
            <input className="sample-input" placeholder="Novo valor" type="number" min={0}
              max={99999}
              value={editsampleInput}
              onChange={(e) => setEditSampleInput(e.target.value)}
            />
            {/* <input className="sample-input" placeholder="Senha" type="password" /> */}

            <span>
              ! A edição afetará o total produzido, exceto a quantidade já etiquetada !
            </span>
          </Form>
          <FlexContainer size={60} direction="row">
            <button className="cancel-btn" onClick={() => setSampleModal(false)}>Cancelar</button>
            <button className="barcode-btn" onClick={() => {
              if (Number(editsampleInput) >= 0) {
                updateSampleBox({
                  variables: {
                    qtd: Number(editsampleInput),
                    id_equip: id,
                    id_prod_order: order[0].id_production_order,
                  }
                });
              }
              setSampleModal(false);
            }}>
              Confirmar
            </button>
          </FlexContainer>

        </Modal>

        {/* Refresh to update   */}
        <Modal isOpen={updateModal} onClickToClose={() => setUpdateModal(false)}>
          <FlexContainer size={140} direction="column">
            <p style={{ fontWeight: 'bold', fontSize: 22 }}>Nova atualização disponível!&nbsp;&nbsp;</p><br />
          </FlexContainer>

          <FlexContainer size={60} direction="row">
            <button className="cancel-btn" onClick={() => setUpdateModal(false)}>Fechar</button>
            <button className="barcode-btn" onClick={() => {
              /* localStorage.clear();
              auth().signOut(); */
              window.location.reload();
            }}>
              Atualizar
            </button>
          </FlexContainer>

        </Modal>

        <History boxData={totalBoxData} samplesRemaining={remainingSamples}
          samples={sampleBoxData} boxRead={boxRead} handleDeleteSampleBox={handleDeleteSampleBox}
        />
      </div>
      {
        (id_enterprise === 119) ? <TagBisnago tagData={tagData} /> : <Tag tagData={tagData} />
      }


    </Container>
  );
}

export default AppContainer;