import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f5f5;

  .form-container {
    width: 300px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 2px 3px hsla(0,0%,4%,.1), 0 0 0 1px hsla(0,0%,85%,.1);
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  .input-login {
    width: 90%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  label {
    font-size: 14px;
    line-height: 1;
    color: #9198a4;
    text-transform: uppercase;
    font-weight: 700;
  }
  input {
    font-size: 14px;
    font-weight: 600;
    color: #727272;
    border: 1px solid #f5f5f5 !important;
    height: 36px;
    padding-left: 30px;
  }
  hr {
    width: 90%;
    background-color: #dbdbdb;
    border: none;
    display: block;
    height: 1px;
    margin: 15px;
  }
  .icon-form {
    position: relative;
    top: 28px;
    left: 10px;
    width: 20px;
  }

`;
