import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import colors from 'components/colors';

import {
  baseFontSize,
  baseLineHeight,
  largeFontSize,
  mediumFontSize,
  smallFontSize,
  truncate,
  truncateReverse
} from 'components/settings';

const Text = styled.span`${({
  color,
  isBold,
  isDanger,
  isDisabled,
  isInline,
  isLarge,
  isLight,
  isMedium,
  isPrimary,
  isSecondary,
  isSmall,
  isSuccess,
  isTruncated,
  isTruncatedReverse,
  isWarning,
  noWrap,
  size
}) => {

  if (isSmall) size = smallFontSize;
  if (isMedium) size = mediumFontSize;
  if (isLarge) size = largeFontSize;

  if (isLight) color = colors.lightGray1;
  if (isPrimary) color = colors.primary;
  if (isSecondary) color = colors.secondary;
  if (isSuccess) color = colors.success;
  if (isWarning) color = colors.warning;
  if (isDanger) color = colors.danger;
  if (isDisabled) color = colors.disabled;

  return css`
    color: ${color};
    display: ${isInline ? 'inline-block' : 'block'};
    font-size: ${size}px;
    font-weight: ${isBold ? 'bold' : 'initial'};
    line-height: 1.2;
    white-space: ${noWrap ? 'nowrap' : 'initial'};
    ${isTruncated && truncate}
    ${isTruncatedReverse && truncateReverse}
    + ${Text} {
      margin-top: 4px;
    }
    > ${Text} {
      display: inline-block;
    }
  `;

}}`;

Text.defaultProps = {
  color: colors.darkGray1,
  isBold: false,
  isDanger: false,
  isDisabled: false,
  isInline: false,
  isLarge: false,
  isLight: false,
  isMedium: false,
  isPrimary: false,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTruncated: false,
  isTruncatedReverse: false,
  isWarning: false,
  noWrap: false,
  size: baseFontSize
};

Text.propTypes = {
  color: PropTypes.string,
  isBold: PropTypes.bool,
  isDanger: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInline: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLight: PropTypes.bool,
  isMedium: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isTruncated: PropTypes.bool,
  isTruncatedReverse: PropTypes.bool,
  isWarning: PropTypes.bool,
  noWrap: PropTypes.bool,
  size: PropTypes.number
};

export default Text;
