import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCRK02fBbgho-VSQrjt5bIZzzVdoIgpRGo',
  authDomain: 'fbpackiot.firebaseapp.com',
  databaseURL: 'https://fbpackiot.firebaseio.com',
  projectId: 'fbpackiot',
  storageBucket: 'fbpackiot.appspot.com',
  messagingSenderId: '969023787798'
};

export const initFirebase = () => firebase.initializeApp(firebaseConfig);

export { default as auth } from './auth';
