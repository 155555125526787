import React from 'react';

import { Router } from '@reach/router';

import SignIn from './components/SignIn';


const Routes = () => (
  <Router>
    <SignIn path="/" />
  </Router>
);

export default Routes;
