import React from 'react';
import styled from 'styled-components';
import colors from '../../colors';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background:  ${(props) => props.white ? '#fff' : '#E2E2E7'};
`;
const Grid = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${colors.blueDark};
    animation: lds-grid 1.2s linear infinite;
  }
  div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
 div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

`;

export default function Loading(props) {
  const { white } = props;
  return (
    <Container white={white}>
      <Grid>
        <div /><div /><div /><div /><div /><div /><div /><div /><div />
      </Grid>
    </Container>
  );
}
