import React, { createContext, useReducer } from 'react';
import { mainReducer, INITIAL_STATE } from '../store/reducer';

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, INITIAL_STATE);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      { children }
    </MainContext.Provider>
  );
};

export default MainContextProvider;