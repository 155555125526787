import styled from 'styled-components';
import { DEVICE } from '../../utils/deviceSize';

export const Container = styled.div`
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin: 30px auto;

  /* @media ${DEVICE.laptop} {
    width: 70%;
  } */

`;

export const FlexContainer = styled.div`
  width: 100%;
  height: ${(props) => props.size}px;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .disabled {
    background: #CECECE !important;
    box-shadow: none !important;
    pointer-events: none;
  }
`;

export const InfoContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const HistoryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const SampleTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .total-sample-input {
    width: 320px;
    height: 42px;
    border: 2px solid #b9b9b9;
    display: flex;
    align-self: center;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
    margin-right: 10px;

  }

  .sample-btn {
    width: 350px;
    height: 42px;
    border: none;
    text-transform: uppercase;
    color: #fff;
    background: #327FF5;
    border-radius: 4px;
    font-weight: 700;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    border-width: 0;
    outline: none;
  }

  #edit-sample-btn {
    background: none;
    border: none;
    width: 250px;
    height: 40px;
    font-weight: bold;
    font-size: 20px;
    color: #333;
  }

`;