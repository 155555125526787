import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { auth } from 'services/firebase';
import { navigate } from '@reach/router';
import { getToken } from 'utils/storage';

export const SessionContext = React.createContext({});

const SessionManager = ({ children }) => {

  const [currentUser, updateCurrentUser] = useState({ stsTokenManager: { accessToken: '' } });
  const [isReady, updateStatus] = useState(null);


  useEffect(() => {
    auth().onAuthStateChanged((currentState) => {
      if (!currentState) navigate(`${process.env.baseURL}auth`);
      if (currentState) {
        const firebaseToken = getToken();
        if (!firebaseToken) {
          auth().signOut();
          navigate(`${process.env.baseURL}auth`);
        }
        updateCurrentUser({ ...currentState.toJSON(), state: auth().currentUser });
        updateStatus(true);
      }
    });
  }, []);

  return (
    <SessionContext.Provider value={{ session: currentUser, isReady }}>
      {children}
    </SessionContext.Provider>
  );
};

SessionManager.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SessionManager;
