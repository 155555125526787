import React from 'react';
import PropTypes from 'prop-types';

import { Title, Icon, Group } from 'components';

import * as icons from 'assets/icons';

import colors from 'components/colors';

const SectionHeader = ({
  icon,
  title
}) => {
  const IconElement = icons[icon];
  return (
    <Group spaceSize={15} isVerticalCentered style={{ marginBottom: 30 }}>
      <Icon color={colors.gray} size={20}>
        {icon && <IconElement />}
      </Icon>
      <Title color={colors.grayLight} hasNoMarginBottom isBold>
        {title.toUpperCase()}
      </Title>
    </Group>
  );
};

SectionHeader.defaultProps = {
  icon: 'Times'
};

SectionHeader.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default SectionHeader;
