import React, { useContext } from 'react';

import { Title, Text, Group, Spacer, Grid, Row, Col } from 'components';
import { MainContext } from '../../../contexts/mainContext';

import colors from 'components/colors';
import { capitalize } from '../../../utils/helpers';

export default function PoHeader({ operator, completed, language, order }) {
  const { state } = useContext(MainContext);
  return (
    <Grid fluid>
      <Row between="xs">
        <Col xs={12}>
          <Group isCentered>
            <Title isMedium isBold>
            Linha {state?.equipment?.name}            
            </Title>
          </Group>
        </Col>
      </Row>
      <Row between="xs">
        <Col xs={6}>
          <Group isVerticalCentered spaceSize={10}>
            <Title isLarge isBold hasNoMarginBottom>
            OP <span id='id_order'>{ order?.length && order[0]?.id_order}</span>
            </Title>
          </Group>
          <Spacer isVertical size={5} />
          <Text color={colors.blue}>{(completed) ? language.completed : 'Em produção'}</Text>
        </Col>
        <Col xs={6}>
          <div style={{ textAlign: 'right' }}>
            <Text isMedium> { order?.length && order[0]?.nm_client} </Text>
            <Spacer isVertical size={5} />
            <Text isMedium> { order?.length && order[0]?.nm_productfamily} </Text>
          </div>
        </Col>
      </Row>
      <Spacer isLarge isVertical />
      <Row>
        <Col xs={12}>
          <Group isVerticalCentered spaceSize={10}>
            <Text>Operador <b>{capitalize(operator)}</b></Text>
          </Group>
        </Col>
      </Row>
    </Grid>
  );
};


