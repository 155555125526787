/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Error, Loading } from 'components';
import { GetInitialInfo } from '../../graphql/queries/queries.graphql';
import { navigate } from '@reach/router';
import { MdChevronRight } from 'react-icons/md';
import { MainContext } from '../../contexts/mainContext';

// styled components
import { Container, Title } from './styles';

export default function SiteManager() {
  const { state, dispatch } = useContext(MainContext);
  let content = '';
  const { loading, error, data } = useQuery(GetInitialInfo,
    {
      onCompleted: (data) => {
        let name = data?.users[0]?.user_name;
        //let roles = data?.users[0]?.user_roles;
        let id = data?.site[0]?.id_enterprise;
        let site = data?.site[0].id_site;
        dispatch({ type: 'USER_INFO', payload: { name, id, site } });
      },
      onError: (error) => {
        if (error.networkError) {
          dispatch({ type: 'NETWORK_STATUS', payload: true });
          // navigate user to main page with last op and production info
          if (state?.equipment?.id) {
            navigate(`${process.env.baseURL}current/${state?.equipment.id}`);
          }
        }
      }
    }
  );

  function handleSubmit(id, lang) {
    //const activeSite = JSON.stringify({ id, name, lang });
    //setActiveSite(activeSite);
    navigate(`${process.env.baseURL}lines`);

  }

  if (error) {
    content = <Error error="Oops! an error occurred, try again later." />;
  }

  if (data) {


    if (data.site.length === 1) {
      /*const activeSite = JSON.stringify({
        id: data?.site[0]?.id_site,
        name: data?.site[0]?.nm_site,
        lang: data?.site[0]?.language_tag
      });
      setActiveSite(activeSite);*/
      navigate(`${process.env.baseURL}lines`);

    } else {
      content = (
        <div className="container">
          <Title>Site</Title>
          <ul>
            {data?.site?.map((item) => (
              <li key={item.id_site} onClick={() => handleSubmit(item.id_site, item.language_tag)}>
                {item.nm_site}
                <MdChevronRight size={18} color="#4a4a4a" />
              </li>
            ))}
          </ul>
        </div>
      );
    }

  }

  return (
    <div>
      {loading ? <Loading /> : (
        <Container>
          {content}
        </Container>
      )
      }
    </div>
  );
}

