import React from 'react';
import { initFirebase } from 'services/firebase';
import { client } from '../services/graphql/client';
import { ApolloProvider } from '@apollo/react-hooks';
import MainContextProvider from '../contexts/mainContext';

import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Router } from '@reach/router';


import { GlobalStyles } from 'components/settings';

import SessionManager from 'containers/SessionManager';
import MainContainer from 'containers/MainContainer';
import SignInFlow from 'containers/SignInFlow';

initFirebase();

const App = () => {
  const basename = document.querySelector('base') ? document.querySelector('base').getAttribute('href') : null;
  return (
    <SessionManager>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <ToastContainer limit={1} transition={Zoom} />
        <MainContextProvider>
          <Router basepath={basename}>
            <SessionManager path="/">
              <MainContainer path="/*" />
              <SignInFlow path="auth/*" />
            </SessionManager>
          </Router>
        </MainContextProvider>
      </ApolloProvider>
    </SessionManager>
  );
};

let exportedApp = App;

export default exportedApp;
