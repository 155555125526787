import React from 'react';
import PropTypes from 'prop-types';

import styled, { css, keyframes } from 'styled-components';

import { fadeInLeft } from 'react-animations';

import colors from 'components/colors';

// TODO: Update to use react-spring and animate when hide too;

const enterLeftAnimation = keyframes`${fadeInLeft}`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Outside = styled.div`
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  ${({ maxWidth, backgroundColor }) => css`
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${maxWidth || 300}px;
    background-color: ${backgroundColor || colors.grayLight};
    animation: .4s ${enterLeftAnimation};
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.3);
  `}
`;

const Content = styled.div`
  overflow: auto;
  flex: 1;
`;

const Drawer = props => {

  const {
    children,
    onClickToHide,
    backgroundColor,
    paddingTop,
    isOpen,
    maxWidth
  } = props;

  if (!isOpen) return null;

  const handleHide = () => {
    onClickToHide();
  };

  return (
    <Wrapper>
      <Container {...{ maxWidth, backgroundColor }}>
        <Content style={{ paddingTop }}>
          {typeof children === 'function' ? children({ isOpen, hideDrawer: handleHide }) : children}
        </Content>
      </Container>
      <Outside onClick={handleHide} />
    </Wrapper>
  );
};

Drawer.defaultProps = {
  children: () => {},
};

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]),
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.number,
  onClickToHide: PropTypes.func,
  onHide: PropTypes.func,
};

export default Drawer;
