import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { MdCloudOff } from 'react-icons/md';
import { MainContext } from '../../../contexts/mainContext';

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
`;

const Card = styled.div`
  background: #fff;
  padding: 20px 15px 20px 20px;
  border-radius: 10px;
  border-left: 3px solid #ccc;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.15);
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: fixed;
  top: 60px;
`;

const Text = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

function NoConnection() {
  const { state, dispatch } = useContext(MainContext);
  const { isOffline } = state;

  const checkConnection = async() => {

    try {
      const response = await fetch('https://gql.packiot.com/healthz');
      if (response.status === 200) {
        dispatch({ type: 'NETWORK_STATUS', payload: false });
      } else {
        dispatch({ type: 'NETWORK_STATUS', payload: true });
      }

    } catch (error) {
      dispatch({ type: 'NETWORK_STATUS', payload: true });
    }

  };

  useEffect(() => {
    let connection = setInterval(() => {
      checkConnection();
    }, 10000);

    return () => {
      clearInterval(connection);
    };
  }, []);

  return (
    <>
      {isOffline && 
      <Container>
        <Card>
          <MdCloudOff size={22} /> <Text>Sem conexão com a Internet &nbsp;&nbsp; </Text>
        </Card>
      </Container>}
    </>
    
  );
}

export default NoConnection;