import React from 'react';

import { Container, TextError } from './styles';

export default function Error({ error, height, full }) {
  return (
    <Container height={height} full={full}>
      <TextError color="#000">{error}</TextError>
    </Container>
  );
}
