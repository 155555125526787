import styled from 'styled-components';
import { DEVICE } from './deviceSize';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .container {
    margin-top: 20px;
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    @media ${DEVICE.tablet} {
      width: 80%;
    }

    @media ${DEVICE.laptop} {
      width: 60%;
    }

    @media ${DEVICE.desktop} {
      width: 50%;
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 80px;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #bdbdbd;
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #000;
`;
