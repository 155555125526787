import React from 'react';

import styled, { css, keyframes } from 'styled-components';
import { pulse } from 'react-animations';
import { Text, Group, Fill } from 'components';
import colors from 'components/colors';
import { fixNumber } from '../../../utils/helpers';

const pulseAnimation = keyframes`${pulse}`;

const PulseText = styled.div`
  animation: 1s ${pulseAnimation};
  color: ${(props) => props.color};
  display: 'block';
  font-size: 25px;
  font-weight: ${(props) => props.isBold ? 'bold' : 'initial'};
  line-height: 1.2;
  white-space: ${(props) => props.noWrap ? 'nowrap' : 'initial'};
`;

const Container = styled(Group)`
  position: relative;
  align-items: flex-end;
  margin-bottom: 70px;
`;

const Bar = styled.div`${({
  size,
  color
}) => css`
  position: relative;
  width: 115px;
  border-radius: 5px;
  height: ${size}px;
  background-color: ${color || colors.grayLight};
`}`;

const BarLabel = styled.div`
  position: absolute;
  bottom: -70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
`;

const BarValue = styled(Text)`
  font-weight: bold;
  position: absolute;
  top: -25px;
`;

const OrderLine = styled.div`
  position: absolute;
  bottom: ${(props) => props.size}px;
  left:0;
  z-index: 49;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed ${colors.gray};

`;

const PoQuantity = ({ order, boxQuantity, sampleIncrement }) => {
  let data = [];
  let boxQuantityWithSample = boxQuantity + sampleIncrement;
  if (order?.length) data = order[0];
  let orderSize = data?.production_programmed;
  let scrap = data?.gross_production - boxQuantityWithSample;
  if (scrap < 0 || isNaN(scrap)) scrap = 0;

  function calculateBarSize(num, maxValue) {
    let size = (num * 100) / maxValue;
    if (size < 1 || isNaN(size)) size = 1;
    return size;
  }

  let maxValue = Math.max(orderSize, boxQuantityWithSample, scrap);
  let orderSizeBar = calculateBarSize(orderSize, maxValue);
  let producedBar = calculateBarSize(boxQuantityWithSample, maxValue);
  let scrapBar = calculateBarSize(scrap, maxValue);

  let formatedScrap = fixNumber(scrap, 2);
  let totalProd = fixNumber(boxQuantityWithSample, 2);
  let percentScrap = ((data?.gross_production - boxQuantityWithSample) / data?.gross_production) * 100;
  if (percentScrap > 100) percentScrap = 100;
  if (percentScrap < 0) percentScrap = 0;

  return (
    <Container>
      <OrderLine size={orderSizeBar} />
      <Bar size={orderSizeBar}>
        <BarLabel>
          <PulseText isBold noWrap>{fixNumber(orderSize, 2)}</PulseText>
          <Text isBold noWrap> Pedido </Text>
        </BarLabel>
      </Bar>
      <Fill />
      <Bar size={producedBar} color={colors.blueLighter}>
        <BarLabel>
          <PulseText isBold color={colors.blue} noWrap>{totalProd}</PulseText>
          <Text isBold noWrap> Total (Produção + Amostras) </Text>
        </BarLabel>
      </Bar>
      <Fill />
      <Bar size={scrapBar} color={colors.orangeLighter}>
        <BarValue color={colors.orange}>{percentScrap.toFixed(1) + '%'}</BarValue>
        <BarLabel>
          <PulseText isBold color={colors.orange} noWrap>{formatedScrap}</PulseText>
          <Text isBold noWrap> Refugo </Text>
        </BarLabel>
      </Bar>
    </Container>
  );
};

export default PoQuantity;
