import styled, { css } from 'styled-components';

import { Grid as StyledGrid, Row as StyledRow, Col as StyledCol } from 'react-styled-flexboxgrid';

export const Grid = styled(StyledGrid)`
  padding-left: 0;
  padding-right: 0;
`;

export const Row = styled(StyledRow)`
  ${({ isExpanded }) => isExpanded && css`flex: 1;`}
`;

export const Col = styled(StyledCol)`
  ${({ isExpanded }) => isExpanded && css`
    display: flex !important;
    flex-direction: column;
    flex: 1;
  `}
  ${({ align }) => css`justify-content: ${align};`}
  ${({ size }) => size && css`width: ${size}px;`}
`;

export { default as Text } from './lib/Text';
export { default as Drawer } from './lib/Drawer';
export { default as Title } from './lib/Title';
export { default as Group } from './lib/Group';
export { default as Fill } from './lib/Fill';
export { default as Icon } from './lib/Icon';
export { default as Spacer } from './lib/Spacer';

export { default as Button } from './lib/Button';

export { default as Box } from './lib/Box';

export { default as Modal } from './lib/Modal';
export { default as Error } from './lib/Error';
export { default as Loading } from './lib/Loading';
export { default as LoadContainer } from './lib/LoadContainer';
export { default as NoConnection } from './lib/NoConnection';


