import { auth } from 'services/firebase';
import { split, ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';

const API_URL = process.env.apiUrl;

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `https://${API_URL}`
});

const wsLink = new WebSocketLink({
  uri: `wss://${API_URL}`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async() => {
      let token = await auth().currentUser.getIdToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const graphQLErrorHandler = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.error(`[ERROR]: Error trying to execute ${operation.operationName}.`);
    console.error('Error log:', graphQLErrors);
  }
  if (networkError) {
    console.error('Network Error:', networkError);
  }
});

const request = async(operation) => {
  let token = await auth().currentUser.getIdToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
    .then((oper) => request(oper))
    .then(() => {
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })
    .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};


export const client = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    link,
    graphQLErrorHandler
  ]),
  cache,
  defaultOptions: defaultOptions
});
