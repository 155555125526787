/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Container, Title } from '../../utils/listStyle';
import { navigate } from '@reach/router';
import DisplayLines from './DisplayLines';
import { MainContext } from '../../contexts/mainContext';

export default function Lines() {
  const { dispatch } = useContext(MainContext);


  return (
    <Container>
      <>
        <Title>Linhas</Title>
        <div className="container">
          <ul>
            <DisplayLines
              onSelect={(line) => {
                dispatch({ type: 'CURRENT_EQUIPMENT', payload: { name: line.cd_equipment, id: line.id_equipment } });
                navigate(`${process.env.baseURL}current/${line.id_equipment}`);
              }}
            />
          </ul>

        </div>
      </>

    </Container>
  );

};
