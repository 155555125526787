import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    font-weight: bold;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  padding-left: 5px;
`;
