import React, { useState, useEffect } from 'react';

import { Drawer } from 'components';

const useDrawer = (config) => {

  const { defaultState, ...rest } = config || {};

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultState !== undefined) setIsOpen(defaultState);
    return () => setIsOpen(false);
  }, []);

  const toggle = (state) => {
    if (typeof state !== 'boolean') state = !isOpen;
    setIsOpen(state);
  };

  const render = (content) => isOpen && (
    <Drawer
      isOpen
      onClickToHide={() => setIsOpen(false)}
      {...rest}>
      {content}
    </Drawer>
  );

  return [render, toggle];

};

export default useDrawer;
