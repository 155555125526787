import styled from 'styled-components';

export const Container = styled.div`
  width: 100${(props) => props.full ? 'vw' : '%' };
  height: ${(props) => props.height ? props.height + 'px' : '100%' };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextError = styled.p`
  font-weight: bold;
  font-size: 17px;
  color: ${(props) => props.color};
  `;
