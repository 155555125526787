import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import colors, { invertColor, darken } from 'components/colors';

import {
  baseFontSize,
  buttonReset,
  largeFontSize,
  mediumFontSize,
  smallFontSize
} from 'components/settings';

import * as customColors from './customColors';

const COLOR_DEFAULT = colors.blueDark;

const BORDER_RADIUS = 10;
const LEFT_RIGHT_PADDING = 0;
const BORDER_WIDTH = 0;

const Wrapper = styled.button`${({
  backgroundColor,
  backgroundColorHover,
  borderColor,
  borderColorHover,
  labelColor,
  labelColorHover,
  isBare,
  isExpanded,
  isFullWidth,
  isRounded,
  offsetHL,
  offsetHR,
  offsetVB,
  offsetVT,
  size,
  theme
}) => isBare ? buttonReset : css`
  ${buttonReset}
  align-items: center;
  background-color: ${backgroundColor};
  border-color: ${borderColor};
  border-radius: ${BORDER_RADIUS}px;
  border-style: solid;
  border-width: ${BORDER_WIDTH}px;
  display: flex;
  flex-direction: row;
  height: ${size}px;
  justify-content: center;
  margin-bottom: ${offsetVB || 0}px;
  margin-left: ${offsetHL || 0}px;
  margin-right: ${offsetHR || 0}px;
  margin-top: ${offsetVT || 0}px;
  outline: none;
  padding-left: ${LEFT_RIGHT_PADDING || size / 2.2}px;
  padding-right: ${LEFT_RIGHT_PADDING || size / 2.2}px;
  transition: all .15s ease-in-out;
  ${isExpanded && 'flex: 1;'}
  ${isFullWidth && 'width: 100%;'}
  ${!theme.custom && css`
    ${Label} {
      transition: all .15s ease-in-out;
      color: ${labelColor};
    }
    path {
      transition: all .15s ease-in-out;
      fill: ${labelColor};
    }
  `}
  &:disabled { cursor: not-allowed; }
  &:hover {
    background-color: ${backgroundColorHover};
    border-color: ${borderColorHover};
    ${!theme.custom && css`
      ${Label} { color: ${labelColorHover}; }
      path { fill: ${labelColorHover}; }
    `}
  }
  ${isRounded && css`
    border-radius: 100%;
    border: none;
    outline: none;
    padding: 0;
    width: ${size}px;
    height: ${size}px;
    color: ${({ labelColor }) => labelColor};
    path {
      transition: all .15s ease-in-out;
      fill: ${({ labelColor }) => labelColor};
    }
    &:hover {
      path { fill: ${({ labelColorHover }) => labelColorHover}; }
    }
  `}
`}`;

const Content = styled.div``;

const Label = styled.span`${({ fontSize }) => css`
  font-size: ${fontSize}px;
  text-transform: uppercase;
  font-weight: bold;
`}`;

const Button = (props) => {

  const { children, onClick, theme, ...rest } = props;

  let size = 65;
  let fontSize = mediumFontSize;
  let colorsObject = {
    backgroundColor: COLOR_DEFAULT,
    backgroundColorHover: darken(COLOR_DEFAULT, .05),
    borderColor: darken(COLOR_DEFAULT, .1),
    borderColorHover: darken(COLOR_DEFAULT, .2),
    labelColor: invertColor(COLOR_DEFAULT),
    labelColorHover: invertColor(COLOR_DEFAULT)
  };

  const {
    isDanger,
    isDisabled,
    isLarge,
    isMedium,
    isOutlined,
    isPrimary,
    isSecondary,
    isSmall,
    isSuccess,
    isTransparent,
    isWarning
  } = rest;

  if (isSmall) size = size - 4;
  if (isMedium) size = size + 4;
  if (isLarge) size = size + 8;

  if (isSmall) fontSize = smallFontSize;
  if (isMedium) fontSize = mediumFontSize;
  if (isLarge) fontSize = largeFontSize;

  const generateColors = (state) => {
    if (customColors[state]) return customColors[state];
    const keyColor = colors[state];
    if (!keyColor) return {};
    return {
      backgroundColor: keyColor,
      backgroundColorHover: darken(keyColor || COLOR_DEFAULT, .05),
      borderColor: darken(keyColor || COLOR_DEFAULT, .1),
      borderColorHover: darken(keyColor || COLOR_DEFAULT, .2),
      labelColor: invertColor(keyColor || COLOR_DEFAULT),
      labelColorHover: invertColor(keyColor || COLOR_DEFAULT)
    };
  };

  if (isDanger) colorsObject = generateColors('danger');
  if (isPrimary) colorsObject = generateColors('primary');
  if (isSecondary) colorsObject = generateColors('secondary');
  if (isSuccess) colorsObject = generateColors('success');
  if (isWarning) colorsObject = generateColors('warning');
  if (isDisabled || rest.disabled) colorsObject = generateColors('disabled');

  if (isOutlined) colorsObject = generateColors('outlined');
  if (isTransparent) colorsObject = generateColors('transparent');

  if (theme) colorsObject = generateColors(theme);

  return (
    <Wrapper
      {...rest}
      size={size}
      theme={{ custom: theme }}
      {...colorsObject}
      disabled={isDisabled || rest.disabled}
      onClick={!(isDisabled || rest.disabled) ? onClick : null}>
      {/* <Content> */}
      {typeof children === 'string' ? (
        <Label fontSize={fontSize}>{children}</Label>
      ) : (
        typeof children === 'function' ? (
          children({
            ...rest,
            colors: colorsObject
          })
        ) : (
          children
        )
      )}
      {/* </Content> */}
    </Wrapper>
  );

};

Button.defaultProps = {
  children: null,
  isBare: false,
  isDanger: false,
  isDisabled: false,
  isExpanded: false,
  isFullWidth: false,
  isLarge: false,
  isMedium: false,
  isOutlined: false,
  isPrimary: true,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTransparent: false,
  isWarning: false,
  offsetHL: null,
  offsetHR: null,
  offsetVB: null,
  offsetVT: null,
  onClick: () => {},
  theme: null
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.array
  ]),
  isBare: PropTypes.bool,
  isDanger: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isWarning: PropTypes.bool,
  offsetHL: PropTypes.number,
  offsetHR: PropTypes.number,
  offsetVB: PropTypes.number,
  offsetVT: PropTypes.number,
  onClick: PropTypes.func,
  theme: PropTypes.string
};

export default Button;
