import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import colors from 'components/colors';

import {
  baseFontSize,
  largeFontSize,
  mediumFontSize,
  smallFontSize,
  truncate,
  truncateReverse
} from 'components/settings';

const MULTIPLIER = 1;
const MARGIN_BOTTOM = 6;

const Title = styled.h1`${({
  color,
  hasNoMarginBottom,
  isBlock,
  isBold,
  isDanger,
  isDark,
  isDisabled,
  isLarge,
  isLight,
  isMedium,
  isPrimary,
  isSecondary,
  isSmall,
  isSuccess,
  isTruncated,
  isTruncatedReverse,
  isWarning,
  size
}) => {

  if (isSmall) size = smallFontSize * MULTIPLIER;
  if (isMedium) size = mediumFontSize * MULTIPLIER;
  if (isLarge) size = largeFontSize * MULTIPLIER;

  if (isLight) color = colors.light;
  if (isDark) color = colors.dark;
  if (isPrimary) color = colors.primary;
  if (isSecondary) color = colors.secondary;
  if (isSuccess) color = colors.success;
  if (isWarning) color = colors.warning;
  if (isDanger) color = colors.danger;
  if (isDisabled) color = colors.disabled;

  return css`
    color: ${color};
    display: ${isBlock ? 'block' : 'inline-block'};
    font-size: ${size}px;
    font-weight: ${isBold ? 'bold' : 'initial'};
    line-height: 1;
    margin: 0;
    margin-bottom: ${hasNoMarginBottom ? 0 : MARGIN_BOTTOM}px;
    ${isTruncated && truncate}
    ${isTruncatedReverse && truncateReverse}
  `;

}}`;

Title.defaultProps = {
  color: 'inherit',
  hasNoMarginBottom: false,
  isBlock: true,
  isBold: false,
  isDanger: false,
  isDark: false,
  isDisabled: false,
  isLarge: false,
  isLight: false,
  isMedium: false,
  isPrimary: false,
  isSecondary: false,
  isSmall: false,
  isSuccess: false,
  isTruncated: false,
  isTruncatedReverse: false,
  isWarning: false,
  size: baseFontSize * MULTIPLIER
};

Title.propTypes = {
  color: PropTypes.string,
  hasNoMarginBottom: PropTypes.bool,
  isBlock: PropTypes.bool,
  isBold: PropTypes.bool,
  isDanger: PropTypes.bool,
  isDark: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLight: PropTypes.bool,
  isMedium: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isTruncated: PropTypes.bool,
  isTruncatedReverse: PropTypes.bool,
  isWarning: PropTypes.bool,
  size: PropTypes.number
};

export default Title;
