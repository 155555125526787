import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Spacer from 'components/lib/Spacer';

export const Fill = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

const Wrapper = styled.div`${({
  isVertical,
  isFullWidth,
  padding,
  isVerticalCentered,
  isCentered,
  isGrown,
  isWrapped,
}) => css`

  width: ${isFullWidth ? '100%' : 'auto'};
  display: flex;
  flex-direction: ${isVertical ? 'column' : 'row'};

  ${isWrapped && css`flex-wrap: wrap;`}

  align-items: ${isVerticalCentered ? 'center' : 'flex-start'};
  justify-content: ${isCentered ? 'center' : 'flex-start'};
  text-align: ${isCentered ? 'center' : 'left'};

  ${isVertical && isCentered && css`
    align-items: ${isCentered ? 'center' : 'flex-start'};
    justify-content: ${isVerticalCentered ? 'center' : 'flex-start'};
  `};

  ${isGrown && css`flex-grow: 1;`}

  padding: ${(() => {
    let paddingToUse = padding;
    if (typeof padding === 'number') paddingToUse = padding + 'px';
    return paddingToUse;
  })()};

`}`;

const Group = ({
  children,
  isVertical,
  isFullWidth,
  spaceSize,
  padding,
  isVerticalCentered,
  isCentered,
  isGrown,
  isWrapped,
  ...rest
}) => (
  <Wrapper
    isVertical={isVertical}
    isFullWidth={isFullWidth}
    spaceSize={spaceSize}
    padding={padding}
    isVerticalCentered={isVerticalCentered}
    isCentered={isCentered}
    isGrown={isGrown}
    isWrapped={isWrapped}
    {...rest}>
    {Children.toArray(children).map((element, index) => (
      <Fragment key={index}>
        {index !== 0 && <Spacer isVertical={isVertical} size={spaceSize} />}
        {element}
      </Fragment>
    ))}
  </Wrapper>
);

Group.defaultProps = {
  isCentered: null,
  isFullWidth: false,
  isGrid: false,
  isGrown: false,
  isVertical: false,
  isVerticalCentered: false,
  isWrapped: false,
  padding: 0,
  spaceSize: 5,
};

Group.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isGrid: PropTypes.bool,
  isGrown: PropTypes.bool,
  isVertical: PropTypes.bool,
  isVerticalCentered: PropTypes.bool,
  isWrapped: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spaceSize: PropTypes.number
};

export default Group;
