/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GetLines } from '../../graphql/queries/queries.graphql';
import { Loading, Error } from 'components';
import { MdChevronRight } from 'react-icons/md';

const DisplayLines = ({ onSelect }) => {

  const { loading, error, data } = useQuery(GetLines);

  if (error) return <Error height={200} error="Oops! an error occurred, try again later =D" />;
  if (loading) return <Loading />;

  let lines = [];

  if (data && data.equipments) {
    lines = data.equipments.map((item) => (
      <li key={item.id_equipment} onClick={() => onSelect(item)}>
        {item.cd_equipment}
        <MdChevronRight size={18} color="#4a4a4a" />
      </li>
    ));
  }
  

  return lines;
};

export default DisplayLines;
