import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-block;

  .tooltip-text {
    background: #fff;
    color: #222;
    font-size: 14px;
    font-weight: bold;
    position:  absolute;
    bottom: 0;
    min-width: 100px;
    left: -38px;
    right: -25px;
    padding: 8px 8px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  &:hover .tooltip-text {
    bottom: 24px;
    visibility: visible;
    opacity: 1;
  }
  .tooltip-text:before {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    position: absolute;
    bottom: -5px;
    left: 40px;
  }
`;


const Tooltip = ({ children, text }) => {
  return(
    <Container>
      {children}
      <span className='tooltip-text'>{text}</span>
    </Container>
  );
}

export default Tooltip;