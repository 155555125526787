/* eslint-disable max-len */
import gql from 'graphql-tag';

export const SET_BOX = gql`
    mutation SetBox($box: Int, $id_enterprise: bigint, $id_equip: bigint, 
    $id_order: bigint, $id_prod_order: bigint, $site: bigint, $ts: timestamptz, $qtd: Int) {
    insert_scanned_boxes(objects: {
      box_order_number: $box, 
      increment: $qtd,
      id_enterprise: $id_enterprise, 
      id_equipment: $id_equip, 
      id_order: $id_order, 
      id_production_order: $id_prod_order, 
      id_site: $site, 
      ts_value: $ts
      }) {
    affected_rows
  }
}

`;

export const SET_MULTIPLE_BOXES = gql`
  mutation insert_multiple_boxes($objects: [scanned_boxes_insert_input!]! ) {
  insert_scanned_boxes(objects: $objects) {
    returning {
      box_order_number
    } 
  }
}
`;

export const INSERT_SAMPLE_INCREMENT = gql`
mutation InsertSampleBox($box: Int, $id_enterprise: bigint, $id_equip: bigint, $id_order: bigint, $id_prod_order: bigint, $site: bigint, $ts: timestamptz, $qtd: Int, $should_increment: Boolean) {
  insert_sample_boxes(objects: {box_order_number: $box, id_enterprise: $id_enterprise, id_equipment: $id_equip, id_order: $id_order, id_production_order: $id_prod_order, id_site: $site, increment: $qtd, should_increment: $should_increment, ts_value: $ts}) {
    affected_rows
  }
  insert_scanned_boxes(objects: {
      box_order_number: $box, 
      increment: $qtd,
      id_enterprise: $id_enterprise, 
      id_equipment: $id_equip, 
      id_order: $id_order, 
      id_production_order: $id_prod_order, 
      id_site: $site, 
      ts_value: $ts
      }) {
    affected_rows
  }
}
`;


export const INSERT_SAMPLE_BOX = gql`
mutation InsertSampleBox(
  $box: Int
  $id_enterprise: bigint
  $id_equip: bigint
  $id_order: bigint
  $id_prod_order: bigint
  $site: bigint
  $ts: timestamptz
  $qtd: Int
  $should_increment: Boolean
) {
  insert_sample_boxes(
    objects: {
      box_order_number: $box
      id_enterprise: $id_enterprise
      id_equipment: $id_equip
      id_order: $id_order
      id_production_order: $id_prod_order
      id_site: $site
      increment: $qtd
      should_increment: $should_increment
      ts_value: $ts
    }
  ) {
    affected_rows
  }
}
`;

export const UPDATE_SAMPLE_BOX = gql`
mutation UpdateSampleBox($id_equip: bigint, $id_prod_order: bigint, $qtd: Int) {
  update_sample_boxes(
    where: {
      id_equipment: { _eq: $id_equip }
      id_production_order: { _eq: $id_prod_order }
      should_increment: { _eq: true }
    }
    _set: { increment: $qtd }
  ) {
    affected_rows
  }
  update_scanned_boxes(
    where: {
      id_equipment: { _eq: $id_equip }
      id_production_order: { _eq: $id_prod_order }
      box_order_number: { _eq: 0 }
    }
    _set: { increment: $qtd }
  ) {
    affected_rows
  }
}

`;

export const DELETE_SAMPLE_BOX = gql`
mutation DeleteSampleBox(
  $id_equip: bigint
  $id_prod_order: bigint
  $id_box: bigint
) {
  delete_sample_boxes(
    where: {
      id_equipment: { _eq: $id_equip }
      id_production_order: { _eq: $id_prod_order }
      id_box: { _eq: $id_box }
    }
  ) {
    affected_rows
  }
}

`;