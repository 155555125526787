import React from 'react';
import Barcode from 'react-barcode';
import '../print.css';
import bispharmaLogo from 'statics/media/logo-bispharma-peb.png';

function Tag({ tagData }) {
  return (
    <div id="container">
      <div className="header">
        <div id="logo">
          <img src={bispharmaLogo} width="88" height="32" alt="logo" />
        </div>
        <span id="title">&nbsp;BISPHARMA EMBALAGENS LTDA&nbsp;&nbsp;</span>
        <div id="tag-number">
          <span>Nº</span>
          {tagData?.isSample && <span>Amostra</span>}

          <span id="number"> <strong>{(tagData?.number == 0) ? 'EC' : tagData?.number}</strong> </span>
        </div>
      </div>

      <p id="sub-title"> Manter protegido do sol, umidade e poeira </p>

      <div className="row" style={{ fontSize: 13 }}>
        <span>Cliente: </span>
        <span id="client">
          <strong style={(tagData?.client?.length > 35) ? { fontSize: 8 } : { fontSize: 13 }}>
            {tagData?.client}
          </strong>
        </span>
      </div>

      <div className="col" style={{ fontSize: 13 }}>
        <span>Bisnaga de Alumínio:&nbsp;</span> <strong>{tagData?.size}</strong>&nbsp;&nbsp;
        <span>Validade:&nbsp;</span> <strong> {tagData?.due_date} </strong>
      </div>

      <div className="row" style={{ fontSize: 13 }}>
        <span>Produto: </span>
        <strong style={(tagData?.product?.length > 35) ? { fontSize: 9 } : { fontSize: 13 }}>
          {tagData?.product}
        </strong>
      </div>

      <div className="col" style={{ fontSize: 13 }}>
        <span>Cód. Prod:&nbsp;</span> <strong style={(tagData?.codProd?.length > 16) ? { fontSize: 9 } : { fontSize: 13 }} > {tagData?.codProd} </strong>&nbsp;&nbsp;
        <span>Cód. Esp:&nbsp;</span> <strong style={(tagData?.codEsp?.length > 16) ? { fontSize: 9 } : { fontSize: 13 }}> {tagData?.codEsp} </strong>
      </div>

      <div className="col" style={{ fontSize: 13 }}>
        <span>Nº Pedido:&nbsp;</span> <strong style={(tagData?.orderNum?.length > 16) ? { fontSize: 9 } : { fontSize: 13 }}> {tagData?.orderNum} </strong>&nbsp;&nbsp;
        <span>OP:&nbsp;</span> <strong> {tagData?.job} </strong>&nbsp;&nbsp;
        <span>Qtde/Cx:&nbsp;</span> <strong > {tagData?.boxQty} </strong>
      </div>


      <div className="flex-container">
        <div className="box" style={{ flexBasis: 80, marginLeft: '10px' }}>
          <span className="header">Rev. E</span>
          <span className="box-content">................................</span>
        </div>
        <div className="box" style={{ flexBasis: 80 }}>
          <span className="header">Rev. M</span>
          <span className="box-content">.................................</span>
        </div>
        <div className="box" style={{ marginLeft: 3 }}>
          <span className="header">Linha</span>
          <span className="box-content">{tagData?.line}</span>
        </div>
        <div className="box" style={{ marginLeft: '-5px' }}>
          <span className="header">Fab</span>
          <span className="box-content">{tagData?.date}</span>
        </div>
        <div className="box" style={{ marginRight: '20px' }}>
          <span className="header">Hora</span>
          <span className="box-content">{tagData?.hour}</span>
        </div>

      </div>

      <div className="barcode-container">
        <Barcode
          value={tagData?.barcode}
          width={1.6}
          height={30}
          margin={3}
          displayValue={false}
        />
      </div>

    </div>
  );
}

export default Tag;