/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { FaEnvelope, FaLock } from 'react-icons/fa';

import { navigate } from '@reach/router';
import PackiotImage from 'statics/media/packiot-dark.jpg';
import { auth } from 'services/firebase';

import { Container } from '../styles';
import { setToken } from 'utils/storage';
import { Loading } from 'components';



export default function SignIn() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);

  function resetForm() {
    setEmail('');
    setPassword('');
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    auth().signInWithEmailAndPassword(email, password)
    .then((response) => {
      setToken(response.user.uid);
      navigate(`${process.env.baseURL}site`);
    })
    .catch(({ message }) => {
      setFeedback(message);
      resetForm();
      setLoading(false);
    });
    
  }
  
  return (
    <Container>
      {
        (loading) ? <Loading /> : (
          <div className="form-container">
            <img src={PackiotImage} alt="logo" width="250" height="60" />
            <form onSubmit={(e) => handleSubmit(e)}>
              <hr />
              <div className="input-login">
                <label className="label">EMAIL</label>
                <span className="icon-form"> <FaEnvelope size={14} color="#dbdbdb" /> </span>
                <input
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ border: '1px solid' }}
                />
              </div>

              <div className="input-login">
                <label className="label">PASSWORD</label>
                <span className="icon-form"> <FaLock size={14} color="#dbdbdb" /> </span>
                <input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ border: '1px solid' }}
                />
              </div>

              <hr />
              <button type="submit" className="login-btn" >SIGN IN</button>  
              
            </form>

          </div>
        )
      }
      {(feedback) ? <p>{feedback}</p> : ''}
    </Container>
  );
};

