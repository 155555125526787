import React from 'react';

import styled, { css } from 'styled-components';

import { baseFontSize } from 'components/settings';

const Wrapper = styled.span`${({ hasChildren, color, size }) => css`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  ${!hasChildren && css`height: ${size || baseFontSize}px;`}
  font-size: ${size ? size + 'px' : 'inherit'};
  color: ${color || 'inherit'};
  ${hasChildren && css`
    svg {
      width: auto;
      height: ${size ? size + 'px' : 'auto'};
    }
    path,
    circle {
      fill: ${color || 'inherit'}
    }
  `}
`}`;

const Icon = ({
  children,
  color,
  name,
  size,
  ...rest
}) => (
  <Wrapper hasChildren={children} {...{ color, size }} {...rest}>
    {children || name && <i className={name} />}
  </Wrapper>
);

export default Icon;
