import colors from 'components/colors';

export const outlined = {
  backgroundColor: 'transparent',
  backgroundColorHover: colors.white,
  borderColor: colors.primary,
  borderColorHover: colors.primaryDark,
  labelColor: colors.primary,
  labelColorHover: colors.primaryDark
};

export const transparent = {
  backgroundColor: 'transparent',
  backgroundColorHover: 'transparent',
  borderColor: 'transparent',
  borderColorHover: 'transparent',
  labelColor: colors.primary,
  labelColorHover: colors.primaryDark
};

export const rounded = {
  backgroundColor: colors.white,
  backgroundColorHover: colors.white,
  borderColor: 'transparent',
  borderColorHover: 'transparent',
  labelColor: colors.primary,
  labelColorHover: colors.primaryDark
};

export const secondary = {
  backgroundColor: colors.white,
  backgroundColorHover: colors.white,
  borderColor: 'transparent',
  borderColorHover: 'transparent',
  labelColor: colors.primary,
  labelColorHover: colors.primaryDark
};

export const disabled = {
  backgroundColor: colors.secondaryLight,
  backgroundColorHover: colors.secondaryLight,
  borderColor: 'transparent',
  borderColorHover: 'transparent',
  labelColor: colors.white,
  labelColorHover: colors.white
};
