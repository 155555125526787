import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .container {
    margin-top: 20px;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #000;
`;
