import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import colors, { convertHexToRGB } from 'components/colors';
import { elevation } from 'components/settings';

const Box = styled.div`${({
  borderRadius,
  borderStyle,
  color,
  isBordered,
  isElevated,
  isFullWidth,
  isInline,
  margin,
  opacity,
  padding
}) => css`
  align-items: ${isInline ? 'center' : 'initial'};
  background-color: ${color ? convertHexToRGB(color, opacity) : convertHexToRGB(colors.white, opacity)};
  border-radius: ${borderRadius}px;
  border: ${isBordered ? `1px solid ${colors.gray1}` : 'none'};
  display: flex;
  flex-direction: ${isInline ? 'row' : 'column'};
  justify-content: flex-start;
  margin: ${margin ? margin : '0'}px;
  overflow: hidden;
  padding: ${padding}px;
  width: ${isFullWidth ? '100%' : 'auto'};
  ${borderStyle && css`border-style: ${borderStyle}`};
  ${isElevated && elevation};
`}`;

Box.defaultProps = {
  borderRadius: 6,
  padding: 10
};

Box.propTypes = {
  borderRadius: PropTypes.number,
  color: PropTypes.number,
  isBordered: PropTypes.bool,
  isElevated: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isInline: PropTypes.bool,
  margin: PropTypes.number,
  opacity: PropTypes.number,
  padding: PropTypes.number
};

export default Box;
